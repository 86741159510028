// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-device-eligibility-jsx": () => import("./../../../src/pages/device-eligibility.jsx" /* webpackChunkName: "component---src-pages-device-eligibility-jsx" */),
  "component---src-pages-device-info-jsx": () => import("./../../../src/pages/device-info.jsx" /* webpackChunkName: "component---src-pages-device-info-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-handle-payment-response-jsx": () => import("./../../../src/pages/handlePaymentResponse.jsx" /* webpackChunkName: "component---src-pages-handle-payment-response-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-payment-failed-jsx": () => import("./../../../src/pages/payment-failed.jsx" /* webpackChunkName: "component---src-pages-payment-failed-jsx" */),
  "component---src-pages-payment-jsx": () => import("./../../../src/pages/payment.jsx" /* webpackChunkName: "component---src-pages-payment-jsx" */),
  "component---src-pages-payment-success-jsx": () => import("./../../../src/pages/payment-success.jsx" /* webpackChunkName: "component---src-pages-payment-success-jsx" */),
  "component---src-pages-personal-info-jsx": () => import("./../../../src/pages/personal-info.jsx" /* webpackChunkName: "component---src-pages-personal-info-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-qr-scan-jsx": () => import("./../../../src/pages/qr-scan.jsx" /* webpackChunkName: "component---src-pages-qr-scan-jsx" */),
  "component---src-pages-select-product-jsx": () => import("./../../../src/pages/select-product.jsx" /* webpackChunkName: "component---src-pages-select-product-jsx" */)
}

